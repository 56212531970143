<template>
  <div class="studentSelector">
    Student selector
    <v-row>
      <v-col cols="6">search</v-col>
      <v-col cols="6">clear button</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="list">
          <div class="list-item" v-for="(item, index) in items">
            {{ item.name }}<br />
            {{ item.email }}<br />
            {{ item.masters }}
          </div>
        </div>
      </v-col>
      <v-col cols="6">added users</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['items'],
};
</script>

<style lang="scss">
.studentSelector {
  .list {
    height: 400px;
    overflow-y: scroll;

    .list-item {
      height: 75px;
      content-visibility: auto;
      contain-intrinsic-height: 75px;
    }
  }
}
</style>
