<template>
  <div>
    <f-container topBottom>
      <v-card elevation="0">
        <v-card-text>
          <v-form
            v-if="!isLoading"
            ref="form"
            v-model="flags.isValid"
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <!-- TITLE -->
                <v-text-field
                  v-model="team.name"
                  :placeholder="$i18n.t('labels.teamName')"
                  :rules="requiredRule"
                  :disabled="isLoading"
                  outlined
                />

                <!-- MASTERS -->
                <f-master-select
                  v-allowed="[userTypes.ADMIN]"
                  v-model="team.masters"
                  :countries="$store.state.country.list"
                  :userMasters="$store.state.user.masters"
                  :is-multiple="true"
                  :disabled="isLoading"
                />

                <!-- USERS -->
                <v-progress-linear
                  v-if="$store.state.team.loadingUsers"
                  indeterminate
                />
                <div v-if="!$store.state.team.loadingUsers">
                  <!-- LEADERS -->
                  <v-autocomplete
                    v-model="team.leaders"
                    :items="leaderUsers"
                    :label="$t('labels.teamLeader')"
                    :rules="requiredRule"
                    :clearable="true"
                    :disabled="isLoading"
                    multiple
                    chips
                    outlined
                    class="userSelect"
                    :counter="true"
                  >
                    <template #item="{item, attrs}">
                      <div class="users--subItem">
                        {{ item.text }}<br />
                        <div class="users--subTitle">{{ item.email }}</div>
                      </div>
                    </template>
                    <template #selection="{item, attrs}">
                      <v-chip>
                        <div class="userSelect-chip">
                          <div>{{ item.text }}</div>
                          <div class="userSelect-chip--subtitle">
                            {{ item.email }}
                          </div>
                        </div>
                      </v-chip>
                    </template>
                  </v-autocomplete>

                  <!-- MEMBERS -->
                  <v-autocomplete
                    v-model="team.members"
                    :items="studentsAndMasters"
                    :label="$t('labels.teamMembers')"
                    :rules="requiredRule"
                    :clearable="true"
                    :disabled="isLoading"
                    multiple
                    chips
                    outlined
                    class="userSelect"
                    :counter="true"
                  >
                    <template #item="{item, attrs}">
                      <div class="users--subItem">
                        {{ item.text }}<br />
                        <div class="users--subTitle">{{ item.email }}</div>
                      </div>
                    </template>
                    <template #selection="{item, attrs}">
                      <v-chip>
                        <div class="userSelect-chip">
                          <div>{{ item.text }}</div>
                          <div class="userSelect-chip--subtitle">
                            {{ item.email }}
                          </div>
                        </div>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>
                <team-status
                  :status="team.status"
                  @change="changeStatus"
                ></team-status>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-col cols="12">
        <lms-btn-holder>
          <v-btn
            color="primary"
            large
            elevation="0"
            @click="save"
            :disabled="isLoading"
            :loading="isLoading"
          >
            {{ flags.isEditing ? $t('labels.save') : $t('labels.create') }}
          </v-btn>

          <v-btn large depressed @click="back">
            {{ $t('labels.cancel') }}
          </v-btn>
        </lms-btn-holder>
      </v-col>
    </f-container>
  </div>
</template>

<script>
import validatorRules from '../../../helpers/validator_rules';
import { UserType } from '../../../helpers/user_utils';
import TeamStatus from '../TeamStatus.vue';
import MasterSelect from '../../shared/MasterSelect.vue';
import StudentSelector from '../../shared/StudentSelector.vue';

export default {
  components: {
    'team-status': TeamStatus,
    'f-master-select': MasterSelect,
    StudentSelector,
  },
  data: () => ({
    status: 'disabled',
    flags: {
      isEditing: false,
      isValid: false,
    },
    id: null,
    prevRoute: null,
  }),
  computed: {
    team() {
      let team = this.$store.state.team.view;
      if (this.$store.getters['auth/user'].type == UserType.Master) {
        team.masters = [this.$store.getters['auth/user'].master];
      }
      return team;
    },
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
    minLengthRule() {
      return [value => value.length > 0 || this.$t('rules.required')];
    },
    leaderUsers() {
      return this.$store.getters['team/getUsers']
        .filter(user => user.type != UserType.Student)
        .map(this.formatUserOption);
    },
    studentsAndMasters() {
      const users = this.$store.getters['team/getUsers'];
      if (users.length > 0) {
        const masterDivider = [
          { divider: true, header: `${this.$t('labels.masters')}/Admins` },
        ];
        const studentDivider = [
          { divider: true, header: this.$t('labels.students') },
        ];
        const leaderUsers = users
          .filter(
            user =>
              !this.team.leaders.includes(user._id) &&
              user.type != UserType.Student
          )
          .map(this.formatUserOption);
        const studentUsers = users
          .filter(user => !this.team.leaders.includes(user._id))
          .map(this.formatUserOption);
        return [
          ...masterDivider,
          ...leaderUsers,
          ...studentDivider,
          ...studentUsers,
        ];
      }
      return [];
    },
    selectMemberIcon() {
      if (this.selectedAllMembers) return 'mdi-close-box';
      if (this.selectedSomeMember) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectedAllMembers() {
      return this.team.members.length === this.students.length;
    },
    selectedSomeMember() {
      return this.team.members.length > 0;
    },
  },
  watch: {
    'team.masters': async function(value) {
      this.$store.state.team.loadingUsers = true;
      await this.$store.dispatch('team/loadUsers', value);
    },
  },
  async created() {
    this.$store.state.team.firstLoad = true;

    if (this.$route.name == 'team-edit') {
      this.flags.isEditing = true;
      this.id = this.$route.params.id;
    }

    if (
      this.$store.getters['auth/user'].type == UserType.Master &&
      !this.flags.isEditing
    ) {
      this.$store.state.team.loadingUsers = true;

      let master = this.$store.getters['auth/user'].master;
      await this.$store.dispatch('team/loadUsers', master);
    }
  },
  methods: {
    async save() {
      const action = this.flags.isEditing ? 'update' : 'create';

      if (!this.$refs.form.validate()) return false;
      let result, message, messageType;
      if (this.flags.isEditing) {
        result = await this.$store.dispatch('team/update');
        if (result) {
          message = this.$t('alerts.general.successEdit');
          messageType = 'success';
        } else {
          message = this.$t('alerts.general.errorEdit');
          messageType = 'error';

          this.$gtm.trackEvent({
            category: 'teams-admin-actions',
            event: 'click',
            action: 'teams-click',
            label: `teams-response-fail`,
            value: 0,
          });
        }
      } else {
        result = await this.$store.dispatch('team/create');
        if (result) {
          message = this.$t('alerts.general.successCreate');
          messageType = 'success';
        } else {
          message = this.$t('alerts.general.errorCreate');
          messageType = 'error';

          this.$gtm.trackEvent({
            category: 'teams-admin-actions',
            event: 'click',
            action: 'teams-click',
            label: `teams-response-fail`,
            value: 0,
          });
        }
      }
      this.$store.commit('snackbar/show', {
        content: message,
        type: messageType,
      });
      if (result) {
        this.$gtm.trackEvent({
          category: 'teams-admin-actions',
          event: 'click',
          action: 'teams-click',
          label: `${action}-teams`,
          value: this.flags.isEditing
            ? `update-${this.$route.params.id}`
            : 'team-create',
        });
        this.back();
      }
    },
    changeStatus(status) {
      this.team.status = status;
    },
    back() {
      this.$router.push({
        name: 'team-list',
      });
      // if (this.flags.isEditing) {
      //   this.$router.push({
      //     name: 'team-view',
      //     params: {
      //       id: this.id,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'team-list',
      //   });
      // }
    },
    selectAllMembers() {
      if (this.selectedAllMembers) {
        this.team.members = [];
      } else {
        this.team.members = this.students.map(member => {
          return member.value;
        });
      }
    },
    formatUserOption(user) {
      return {
        value: user._id,
        text: user.name,
        email: user.email,
      };
    },
  },
  destroyed() {
    this.$store.dispatch('team/clear');
  },
};
</script>

<style lang="scss" scoped>
.userSelect {
  .v-chip.v-size--default {
    height: 38px;
  }
}
.userSelect-chip {
  line-height: 11px;
  .userSelect-chip--subtitle {
    font-size: 0.8em;
    margin-top: -4px !important;
    display: inline-block;
    color: #727272;
    text-transform: lowercase;
  }
}
.users--subItem {
  display: block;
  padding: 10px;

  .users--subTitle {
    font-size: 0.8em;
  }
}
</style>
