<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-form />
  </div>
</template>

<script>
import FForm from '../../components/Teams/createEdit/Form.vue';

export default {
  components: {
    FForm,
  },
  computed: {
    title() {
      let title = this.$t('labels.createTeam');
      if (this.$store.getters['system/pageEditing']) {
        title = this.team.name;
      }
      return title;
    },
    prevLinks() {
      return [
        {
          title: this.$t('labels.teams'),
          route: {
            name: 'team-list',
          },
        },
      ];
    },
    team() {
      return this.$store.state.team.view;
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      await this.$store.dispatch('country/loadList');
      if (this.$route.name == 'team-edit') {
        await this.$store.dispatch('team/get', this.$route.params.id);
      }
      this.$store.commit('system/pageLoaded');
    },
  },
  destroyed() {
    this.$store.dispatch('team/clear');
  },
};
</script>
